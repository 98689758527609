import React from 'react';
import sections from '../../constants/data';
import whyImg from '../../assets/images/why_us.png';
import "./Qualities.css";

const Qualities = () => {
  return (
    <section className='qualities section-p bg-md-black' id = "qualities">
        <div className='container'>
            <div className='qualities-content grid'>
                <div className='content-left'>
                    <div className='section-t text-center'>
                        <h3>Why Choose Us</h3>
                        <p className='text'>Tristar Transport is a family owned and operated business specializing in transportation, transloading and distribution. Rooted in family values and servicing North America since 2000.</p>
                    </div>

                    <div className='item-list grid text-white'>
                        {
                            sections.qualities.map(quality => {
                                return (
                                    <div className='item flex' key = {quality.id}>
                                        <div className='item-icon flex flex-center'>
                                            {quality.icon}
                                        </div>
                                        <div className='item-text'>
                                            <h3 className='item-title fs-25'>{quality.title}</h3>
                                            <p className='text'>{quality.text}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='content-right'>
                    <img src = {whyImg} alt = "" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Qualities
