import customer_img_1 from "../assets/images/customer_img_1.jpg";
import customer_img_2 from "../assets/images/customer_img_2.jpg";
import customer_img_3 from "../assets/images/customer_img_3.jpg";
import customer_img_4 from "../assets/images/customer_img_4.jpg";
import customer_img_5 from "../assets/images/customer_img_5.jpg";
import customer_img_6 from "../assets/images/customer_img_6.jpg";

const images = {
  customer_img_1,
  customer_img_2,
  customer_img_3,
  customer_img_4,
  customer_img_5,
  customer_img_6,
};

export default images;
