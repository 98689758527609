import React from 'react';
import "./Portfolio.css";
import networkImg from '../../assets/images/tristar_map.png';

const Portfolio = () => {
  return (
    <section className='qualities section-p bg-md-black' id = "qualities">
    <div className='container'>
        <div className='qualities-content grid'>
            <div className='content-left'>
                <div className='section-t text-center'>
                    <h3>Our North American Footprint</h3>
                    <p className='text'>We so big pick us</p>
                </div>
            </div>

            <div className='content-right'>
                <img src = {networkImg} alt = "" />
            </div>
        </div>
    </div>
</section>
  )
}

export default Portfolio
