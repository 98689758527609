import React from 'react';
import "./Contact.css";

const Map = () => {
  return (
    <div className='map-content'>
        {/* eslint-disable-next-line */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2615.642110871536!2d-122.3635864233617!3d49.03641347135687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485caa313e0d421%3A0x51241a6ee9c3215e!2s1895%20Townline%20Rd%2C%20Abbotsford%2C%20BC%20V2T%206E2!5e0!3m2!1sen!2sca!4v1729030171686!5m2!1sen!2sca" width="100%" height="450" style={{border:0}}></iframe>
    </div>
  )
}

export default Map
