import React from 'react';
import "./About.css";
import aboutImg from '../../assets/images/tristar-pose.jpg';

const About = () => {
  return (
    <section className='about section-p bg-dark' id = "about">
        <div className='container'>
            <div className='about-content grid text-center'>
                <div className = "content-left">
                    <img src = {aboutImg} alt = "" />
                </div>
                <div className='content-right'>
                    <div className='section-t'>
                        <h3>About Us</h3>
                    </div>
                    <p className = "text">For over 25 years, Tristar Transport has provided reliable logistics, warehousing, and distribution services in the Greater Vancouver Area. We prioritize safety and transparency, with real-time updates and a dedicated team handling your goods. Our goal is to build strong partnerships and exceed expectations on every delivery. Count on us for a seamless supply chain experience that supports your business growth.</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default About